import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";

export const Address = () => {
  const [data, setData] = useState([]);
  const [cntType, setCntType] = useState("IND");
  const getData = async () => {
    const myAdd = await axios.post("http://localhost:3030/addnumber", {
      data: [
        {
          HNo: "1232",
          StreetNo: "231",
          LandMark: "Near Golden Gym",
          city: "Nanded",
          state: "Maharashtra",
          country: "India",
        },
        {
          HNo: "2300",
          StreetNo: "31",
          LandMark: "Near Temple",
          city: "Hyderabad",
          state: "Telangana",
          country: "India",
        },
        {
          HNo: "775577",
          StreetNo: "555",
          LandMark: "Near Masjid",
          city: "New Delhi",
          state: "New Delhi",
          country: "India",
        },
        {
          HNo: "777",
          StreetNo: "100",
          LandMark: "Near Barista Cafe",
          city: "Nagpur",
          state: "Maharashtra",
          country: "India",
        },
      ],
      type: cntType,
      Language: "MAR",
    });
    setData([...myAdd.data]);
  };
  useEffect(() => {
    getData();
  }, [cntType]);
  return (
    <React.Fragment>
      <Button onClick={() => setCntType("IND")}>India</Button>
      <span> </span>
      <Button onClick={() => setCntType("UK")}>United Kingdom</Button>
      <span> </span>
      <Button onClick={() => setCntType("USA")}>USA</Button>
      {data.map((item) => (
        <h1>{item}</h1>
      ))}
    </React.Fragment>
  );
};
