import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { configStore } from "./state/store/configStore";
import { Counter } from "./components/Counter";
import { ProductsList } from "./components/ProductsList";
import { BikeList } from "./components/BikeList";
import { NavLib } from "react-navlib-pickupbiz";
import { Address } from "./components/Address";
import { DataList } from "./components/DataList";
import { DynClass } from "./components/DynClass";
import { CheckRedux } from "./components/CheckRedux";
import { NavBar } from "./components/NavBar";
import "./App.css";

const App = () => {
  const localStore = configStore();
  const links = [
    {
      title: "Home",
      icon: "fa fa-home",
      url: "/",
    },
    {
      title: "Products",
      icon: "fa fa-phone-square",
      url: "/products",
    },
    {
      title: "Vehicles",
      icon: "fa fa-automobile",
      url: "/",
    },
    {
      title: "Redux",
      icon: "fa fa-automobile",
      url: "/redux",
    },
    {
      title: "Address",
      icon: "fa fa-bath",
      url: "/address",
    },
    {
      title: "Shop",
      icon: "fa fa-ambulance",
      url: "/eshop",
    },
    // {
    //   title: "Redux",
    //   icon: "fa fa-cogs",
    //   url: "/redux",
    // },
  ];
  return (
    <Provider store={localStore}>
      <BrowserRouter>
        <NavLib type="cover" links={links} />
        <Routes>
          <Route path="/" exact element={<BikeList />} />
          <Route path="/cls" element={<DynClass />} />
          <Route path="/bike" element={<BikeList />} />
          <Route path="/eshop" element={<DataList />} />
          <Route path="/counter" element={<Counter />} />
          <Route path="/products" element={<ProductsList />} />
          <Route path="/redux" element={<CheckRedux />} />
          <Route path="/address" element={<Address />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
