import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataItem } from "./DataItem";
import { Row, Col } from "react-bootstrap";
import axios from "axios";

export const DataList = () => {
  const seleProds = useSelector((state) => state.productsReducer.products);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [filtData, setFiltData] = useState(data);
  const [txtSearch, setTxtSearch] = useState("");
  const handleSearch = () => {
    const filteredDatas = data.filter((item) =>
      item.title.toUpperCase().includes(txtSearch.toUpperCase())
    );
    txtSearch !== "" ? setFiltData(filteredDatas) : setFiltData(data);
  };

  const getData = async () => {
    if (seleProds.length > 0) {
      setData(seleProds);
    } else {
      const result = await axios.get("https://fakestoreapi.com/products");
      setData(result.data);
      dispatch({
        type: "ADD_PRODUCTS",
        data: result.data,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [txtSearch, data]);

  return (
    <div>
      <Row>
        <Col lg={8}>
          <input
            type="text"
            onChange={(e) => setTxtSearch(e.target.value)}
            placeholder="Search..."
            style={{ width: "700px" }}
          ></input>
        </Col>
      </Row>
      <Row>
        {filtData.map((item, index) => (
          <Col lg={3}>
            <DataItem item={item} index={index} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
