import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

export const CheckRedux = () => {
  const selectData = useSelector((state) => state.productsReducer.products);
  console.log("===>", selectData);
  const dispatch = useDispatch();
  const handleProductClick = () => {
    dispatch({
      type: "SET_PRODUCT_NAME",
      data: "P-DAC",
    });
  };
  const handleClick = () => {
    dispatch({
      type: "ADD_PRODUCTS",
      data: ["Cat", "Bat", "Fat", "Mat"],
    });
  };
  return (
    <div>
      <Button onClick={handleClick}>Dispatch me</Button>
      <br />
      <br />
      <Button onClick={handleProductClick}>Dispatch Name of Product</Button>
      {selectData.map((item) => (
        <p>{item}</p>
      ))}
    </div>
  );
};
