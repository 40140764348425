import React, { useState } from "react";
import "./DynClass.css";

export const DynClass = () => {
  const [blnDyn, setBlnDyn] = useState(false);
  return (
    <div className={blnDyn ? "class1" : "class2"}>
      <p>This is my data</p>
      <button onClick={() => setBlnDyn(!blnDyn)}>Click to switch</button>
    </div>
  );
};
