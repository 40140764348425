import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BikeItem } from "./BikeItem";
import { bikes } from "../services/bikes";
import { Row, Col } from "react-bootstrap";

export const BikeList = () => {
  const seleData = useSelector((state) => state.productsReducer.products);
  const [filtBike, setFiltBike] = useState(bikes);
  const [txtSearch, setTxtSearch] = useState("");
  const handleSearch = () => {
    const filteredBikes = bikes.filter((item) =>
      item.name.toUpperCase().includes(txtSearch.toUpperCase())
    );
    txtSearch !== "" ? setFiltBike(filteredBikes) : setFiltBike(bikes);
  };
  console.log("----->>>>", seleData);
  useEffect(() => {
    handleSearch();
  }, [txtSearch]);

  return (
    <div>
      <Row>{seleData && seleData.map((item) => <h1>{item}</h1>)}</Row>
      <Row>
        <Col lg={8}>
          <input
            type="text"
            onChange={(e) => setTxtSearch(e.target.value)}
            placeholder="Search..."
            style={{ width: "700px" }}
          ></input>
        </Col>
      </Row>
      <Row>
        {filtBike.map((item, index) => (
          <Col lg={3}>
            <BikeItem item={item} index={index} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
